import React, { useState, useEffect, useCallback } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  CardMedia,
  Fab,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { firestore } from 'services/firebase/client';
import { useAuth } from 'services/auth/authContext';
import type { IHousePromotion } from 'utils/types/HousePromotion';
import Header from 'components/Header';
import Routes from 'AppRoutes/Routes';
import { useHouseData } from 'utils/contexts/houseContext';
import PromotionForm from 'components/PromotionForm';
import {
  DeactivateVoucher,
  DeleteDocument,
  createEmptyHousePromotion,
} from 'services/firebase/updates';

const Promotions = (): React.JSX.Element => {
  const { auth } = useAuth();
  const houseData = useHouseData();

  const [promotions, setPromotions] = useState<IHousePromotion[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [selectedPromotion, setSelectedPromotion] =
    useState<IHousePromotion | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const promotionsCollection = collection(firestore, 'promotions');

    const q = query(
      promotionsCollection,
      where('houseID', '==', auth.currentUser?.uid),
    );

    const fetchPromotions = async () => {
      try {
        const querySnapshot = await getDocs(q);
        const promotionData: IHousePromotion[] = [];

        querySnapshot.forEach(doc => {
          const data = doc.data() as IHousePromotion;
          promotionData.push({
            ...data,
            id: doc.id,
          });
        });

        setPromotions(promotionData);
      } catch (error) {
        console.error('Error fetching promotions:', error);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchPromotions();
    setHasChanges(false);
  }, [auth.currentUser?.uid, hasChanges]);

  const handlePromotionCardClick = useCallback((promotion: IHousePromotion) => {
    setSelectedPromotion(promotion);
    // setModalOpen(true);
  }, []);

  const onSave = useCallback((promotion: IHousePromotion) => {
    setHasChanges(true);
    setSelectedPromotion(promotion);
  }, []);

  const handleDeletePromotion = useCallback(async (promotionID: string) => {
    try {
      await DeleteDocument('promotions', promotionID);

      if (promotionID === houseData?.houseData?.promotionID) {
        await DeactivateVoucher('houses', houseData.houseData.id);
      }
      setHasChanges(true);
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  }, []);

  const handleAddPromotion = useCallback(() => {
    if (!houseData?.houseData?.id) {
      return;
    }
    const newPromotion = createEmptyHousePromotion(houseData?.houseData?.id);
    setSelectedPromotion(newPromotion);
  }, [houseData?.houseData?.id]);
  return (
    <>
      <Header route={Routes.PROMOTIONS} />
      <Container
        style={{
          paddingLeft: 40,
          paddingTop: 40,
        }}
      >
        <Container
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            marginBottom: 30,
          }}
        >
          <Fab
            color="secondary"
            aria-label="add"
            // eslint-disable-next-line prettier/prettier
            onClick={handleAddPromotion}
            style={{
              // position: 'absolute',
              // top: '10%',
              left: '90%',
            }}
          >
            <AddIcon />
          </Fab>
        </Container>
        <Container style={{ display: 'flex' }}>
          {!selectedPromotion ? (
            <Grid container spacing={2}>
              {promotions.map(promotion => (
                <Grid item key={promotion.id} xs={12} sm={6} md={4}>
                  <Card
                    onClick={() => handlePromotionCardClick(promotion)}
                    style={{ position: 'relative', width: 300, height: 300 }}
                  >
                    <CardMedia
                      component="img"
                      height="194"
                      image={promotion.image}
                      alt=""
                    />
                    {promotion.id === houseData?.houseData?.promotionID && (
                      <Button
                        style={{
                          backgroundColor: 'green',
                          color: 'white',
                          position: 'absolute',
                          top: '2%',
                          right: '2%',
                          borderRadius: '50%',
                          cursor: 'pointer',
                        }}
                      >
                        Ativa
                      </Button>
                    )}
                    <CardContent>
                      <Typography variant="h6">{promotion.title}</Typography>
                      <Typography variant="body2">
                        {promotion.description}
                      </Typography>
                    </CardContent>
                  </Card>
                  <Fab
                    size="medium"
                    color="primary"
                    aria-label="add"
                    onClick={() => handleDeletePromotion(promotion.id)}
                    style={{
                      marginRight: 0,
                      top: '2%',
                      left: '3%',
                    }}
                  >
                    <DeleteIcon />
                  </Fab>
                </Grid>
              ))}
            </Grid>
          ) : (
            <PromotionForm
              onClose={setSelectedPromotion}
              promotionData={selectedPromotion}
              onSave={onSave}
            />
          )}
        </Container>
      </Container>
    </>
  );
};

export default Promotions;
