import React, { useState, useEffect, useCallback } from 'react';

import {
  Alert,
  Button,
  CardActions,
  Container,
  Typography,
} from '@mui/material';

import type { IHouse } from 'utils/types/Houses';

import './styles.css';
import { Instagram, Phone, Whatsapp } from 'assets/icons';

import type { ICity, IState } from 'utils/data/fetchStates';
import { fetchCitiesByStateId, fetchStates } from 'utils/data/fetchStates';
import ImageLoader from 'components/ImageLoader';
import {
  UpdateHouse2Firebase,
  getCategories,
  uploadHouseImage,
} from 'services/firebase/updates';
import { useHouseData } from 'utils/contexts/houseContext';

interface HouseFormProps {
  houseData: IHouse;
}

const PRICE_VALUES = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
};

const benefitsArray = [
  'pets',
  'kids',
  'internalArea',
  'parking',
  'outsideArea',
  'craftBeer',
];

const HouseForm: React.FC<HouseFormProps> = ({ houseData }) => {
  const houseContext = useHouseData();
  const [formData, setFormData] = useState<IHouse>({ ...houseData });
  const [categories, setCategories] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);
  const [thumbAlert, setThumbAlert] = useState(false);
  const [imageAlert, setImageAlert] = useState(false);
  // const { auth } = useAuth();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    setIsLoading(false);

    if (typeof window !== 'undefined') {
      const handleBeforeUnload = (e: BeforeUnloadEvent) => {
        if (!isFormSaved()) {
          e.returnValue =
            'You have unsaved changes. Are you sure you want to leave?';
        }
      };

      // eslint-disable-next-line no-undef
      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        // eslint-disable-next-line no-undef
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, []);

  const handleThumbImage = useCallback(
    async (file: File) => {
      const publicImageUrl = await uploadHouseImage(file);

      setFormData({
        ...formData,
        thumbnailURL: publicImageUrl,
      });

      setThumbAlert(false);
    },
    [formData],
  );

  const handleImage = useCallback(
    async (file: File) => {
      const publicImageUrl = await uploadHouseImage(file);

      setFormData({
        ...formData,
        image: publicImageUrl,
      });

      setImageAlert(false);
    },
    [formData],
  );

  useEffect(() => {
    const fetchData = async () => {
      setCategories(await getCategories());
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, []);

  const fetchCities = useCallback(async (stateId: string) => {
    const response = await fetchCitiesByStateId(stateId);
    if (response) {
      setCities(response);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchStates();
      if (response) {
        setStates(response);
        await fetchCities(formData.UF);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, []);

  const isFormSaved = () => {
    return JSON.stringify(formData) === JSON.stringify(houseData);
  };

  const handleInputChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = e.target;
      if (e.target.type === 'checkbox') {
        const checkbox = e.target as HTMLInputElement; // Cast event.target to HTMLInputElement

        // Benefits
        if (benefitsArray.includes(name)) {
          // const updateBenefits = formData.benefits;
          const updateBenefits = { ...formData.benefits };

          switch (name) {
            case 'kids':
              updateBenefits.kids = checkbox.checked;
              break;
            case 'pets':
              updateBenefits.pets = checkbox.checked;
              break;
            case 'internalArea':
              updateBenefits.internalArea = checkbox.checked;
              break;
            case 'parking':
              updateBenefits.parking = checkbox.checked;
              break;
            case 'outsideArea':
              updateBenefits.outsideArea = checkbox.checked;
              break;
            case 'craftBeer':
              updateBenefits.craftBeer = checkbox.checked;
              break;
            default:
              console.error('Something went wrong!');
          }

          setFormData({
            ...formData,
            benefits: updateBenefits,
          });
          return;
        }

        // Types
        const updatedTypes = [...formData.type];

        if (checkbox.checked) {
          updatedTypes.push(name);
        } else {
          const index = updatedTypes.indexOf(name);
          if (index !== -1) {
            updatedTypes.splice(index, 1);
          }
        }
        setFormData({
          ...formData,
          type: updatedTypes,
        });
      } else if (e.target.type === 'radio') {
        // Handle radio button changes
        setFormData({
          ...formData,
          [name]: parseInt(value, 10), // Convert the value to an integer
        });
      } else {
        if (name === 'UF') {
          await fetchCities(value);
        }

        setFormData({
          ...formData,
          [name]: value,
        });
      }
    },
    [fetchCities, formData],
  );

  const handleSave = useCallback(async () => {
    console.log(`formData: ${JSON.stringify(formData, null, 2)}`);

    if (formData.thumbnailURL === '') {
      setThumbAlert(true);
      return;
    }

    if (formData.image === '') {
      setImageAlert(true);
      return;
    }

    setIsLoading(true);
    await UpdateHouse2Firebase('houses', formData);
    await houseContext?.updateHouseData(formData.id);
    setIsLoading(false);
  }, [formData, houseContext]);

  return (
    <>
      <Container
        style={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 20,
        }}
      >
        <Container style={{ flex: 2, alignSelf: 'center' }}>
          {thumbAlert && (
            <Alert variant="filled" severity="error">
              <strong>Falta logomarca da casa!</strong>
            </Alert>
          )}
          {imageAlert && (
            <Alert variant="filled" severity="error">
              <strong>Falta imagem de capa!</strong>
            </Alert>
          )}
        </Container>

        <CardActions
          style={{
            flex: 2,
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={isFormSaved()}
          >
            SALVAR
          </Button>
        </CardActions>
      </Container>
      <div>
        <form>
          <div className="block-margin">
            <label htmlFor="name">Razão social:</label>
            <input
              type="text"
              id="corporateName"
              name="corporateName"
              value={formData.corporateName}
              onChange={handleInputChange}
              className="custom-input"
            />
          </div>
          <div className="block-margin">
            <label htmlFor="name">CNPJ:</label>
            <input
              type="text"
              id="cnpj"
              name="cnpj"
              disabled
              value={formData.cnpj}
              onChange={handleInputChange}
              className="custom-input"
            />
          </div>
          <div className="row">
            <div className="input-name">
              <label htmlFor="name">Nome do estabelecimento:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="custom-input"
              />
            </div>

            <div className="block-margin">
              <label htmlFor="category">Estilo de culinária:</label>
              <select
                id="category"
                name="style"
                value={formData.style}
                onChange={handleInputChange}
                className="custom-input"
              >
                <option value="">Selecione uma categoria</option>
                {categories.map(category => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="container">
            <div className="section-title">
              <p>LOCALIZAÇÃO</p>
            </div>
            <div className="row">
              <div className="block-margin">
                <label htmlFor="name">Endereço:</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="block-margin">
                <label htmlFor="name">UF:</label>
                <select
                  id="UF"
                  name="UF"
                  value={formData.UF}
                  onChange={handleInputChange}
                  className="custom-input"
                >
                  {states.map(state => (
                    <option key={state.sigla} value={state.sigla}>
                      {state.sigla}
                    </option>
                  ))}
                </select>
              </div>

              <div className="block-margin">
                <label htmlFor="name">Bairro:</label>
                <input
                  type="text"
                  id="neighborhood"
                  name="neighborhood"
                  value={formData.neighborhood}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="block-margin">
                <label htmlFor="name">Cidade:</label>
                <select
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  className="custom-input"
                >
                  {cities.map(city => (
                    <option key={city.nome} value={city.nome}>
                      {city.nome}
                    </option>
                  ))}
                </select>
              </div>
              <div className="zipcode-row">
                <div className="block-margin">
                  <label htmlFor="name">CEP:</label>
                  <input
                    type="text"
                    id="zipcode"
                    name="zipcode"
                    value={formData.zipcode}
                    onChange={handleInputChange}
                    className="custom-input"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="section-title">
            <div>PERFIL DO RESTAURANTE</div>
          </div>
          <div className="row-profile">
            <div>
              <p className="section-subtitle">Perfil de preço:</p>
              <div className="radio-group">
                <label className="checkbox-label">
                  <input
                    type="radio"
                    name="price"
                    value="1"
                    checked={formData.price === PRICE_VALUES.ONE}
                    onChange={handleInputChange}
                  />
                  $
                </label>
                <label className="checkbox-label">
                  <input
                    type="radio"
                    name="price"
                    value="2"
                    checked={formData.price === PRICE_VALUES.TWO}
                    onChange={handleInputChange}
                  />
                  $$
                </label>
                <label className="checkbox-label">
                  <input
                    type="radio"
                    name="price"
                    value="3"
                    checked={formData.price === PRICE_VALUES.THREE}
                    onChange={handleInputChange}
                  />
                  $$$
                </label>
                <label className="checkbox-label">
                  <input
                    type="radio"
                    name="price"
                    value="4"
                    checked={formData.price === PRICE_VALUES.FOUR}
                    onChange={handleInputChange}
                  />
                  $$$$
                </label>
              </div>
            </div>
            <div>
              <p className="section-subtitle">Serve quais refeições?</p>
              <div className="form-group">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name="Café da manhã"
                    checked={formData.type?.includes('Café da manhã')}
                    onChange={handleInputChange}
                  />
                  Café da manhã
                </label>
              </div>
              <div className="form-group">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name="Brunch"
                    checked={formData.type?.includes('Brunch')}
                    onChange={handleInputChange}
                  />
                  Brunch
                </label>
              </div>
              <div className="form-group">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name="Almoço"
                    checked={formData.type?.includes('Almoço')}
                    onChange={handleInputChange}
                  />
                  Almoço
                </label>
              </div>
              <div className="form-group">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name="Jantar"
                    checked={formData.type?.includes('Jantar')}
                    onChange={handleInputChange}
                  />
                  Jantar
                </label>
              </div>
            </div>
            <div>
              <p className="section-subtitle">Benefícios oferecidos:</p>
              <div className="form-group">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name="parking"
                    checked={formData.benefits.parking}
                    onChange={handleInputChange}
                  />
                  Estacionamento
                </label>
              </div>
              <div className="form-group">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name="kids"
                    checked={formData.benefits.kids}
                    onChange={handleInputChange}
                  />
                  Área Kids
                </label>
              </div>
              <div className="form-group">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name="pets"
                    checked={formData.benefits.pets}
                    onChange={handleInputChange}
                  />
                  Pet friendly
                </label>
              </div>
              <div className="form-group">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name="internalArea"
                    checked={formData.benefits.internalArea}
                    onChange={handleInputChange}
                  />
                  Área interna
                </label>
              </div>
              <div className="form-group">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name="outsideArea"
                    checked={formData.benefits.outsideArea}
                    onChange={handleInputChange}
                  />
                  Área externa
                </label>
              </div>
              <div className="form-group">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name="craftBeer"
                    checked={formData.benefits.craftBeer}
                    onChange={handleInputChange}
                  />
                  Cervejas especiais
                </label>
              </div>
            </div>
            <div>
              <p className="section-subtitle">Contatos:</p>
              <div className="row-container">
                <img className="contacts" src={Instagram} alt="" width={30} />
                <input
                  type="text"
                  id="instagram"
                  name="instagram"
                  value={formData.instagram}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="row-container">
                <img className="contacts" src={Whatsapp} alt="" width={30} />
                <input
                  type="text"
                  id="whatsapp"
                  name="whatsapp"
                  value={formData.whatsapp}
                  onChange={handleInputChange}
                  className="custom-input"
                  placeholder="(XX) XXXXX-XXX"
                />
              </div>
              <div className="row-container">
                <img className="contacts" src={Phone} alt="" width={30} />
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="custom-input"
                  placeholder="(XX) XXXXX-XXX"
                />
              </div>
            </div>
          </div>
          <div className="row-50">
            <div>
              <Typography variant="h5">Imagem de capa:</Typography>
              <Typography variant="body2" style={{ marginLeft: 1 }}>
                Imagem da fachada ou do interior da casa
              </Typography>
              <div className="row-container">
                {/* <img src={formData.image} alt="" width="75%" /> */}
                <ImageLoader
                  initialImage={formData.image}
                  handleImage={handleImage}
                />
                {isLoading && (
                  <div className="loading-overlay">
                    <div className="spinner" />
                  </div>
                )}
              </div>
            </div>
            <div>
              <Typography variant="h5">Logomarca da casa:</Typography>
              <div className="thumbnail-container">
                <ImageLoader
                  initialImage={formData.thumbnailURL}
                  handleImage={handleThumbImage}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default HouseForm;
