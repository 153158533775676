import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyDX_jUYqVVtIT03uKvsKXqPc32SsbwaknE',
  authDomain: 'festgourmet-30c4c.firebaseapp.com',
  projectId: 'festgourmet-30c4c',
  storageBucket: 'festgourmet-30c4c.appspot.com',
  messagingSenderId: '167758341196',
  appId: '1:167758341196:web:a35d99507e28dab563c0e9',
  measurementId: 'G-4CPSL4QTB8',
};

const firebaseClient = initializeApp(firebaseConfig);
const auth = getAuth(firebaseClient);
const firestore = getFirestore(firebaseClient);
const storage = getStorage(firebaseClient);

export { firestore, firebaseClient, auth, storage };
