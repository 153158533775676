import sx from 'utils/createSx';

import theme from 'ThemeProvider/theme';

export default sx({
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    width: 40,
    height: 40,
  },
  navBar: {
    backgroundColor: theme.palette.primary.main,
  },
  profile: {
    color: theme.palette.secondary.dark,
  },
  profileGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 4,
    alignItems: 'center',
  },
  menuIcon: {
    color: theme.palette.secondary.dark,
  },
  text: {
    color: theme.palette.secondary.dark,
  },
});
