import {
  doc,
  updateDoc,
  deleteDoc,
  getDoc,
  setDoc,
  collection,
  query,
  getDocs,
} from 'firebase/firestore';

import type { DocumentData } from 'firebase/firestore';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from 'firebase/storage';

import { storage, firestore, auth } from 'services/firebase/client';

import { generateRandomId } from 'utils/helpers';
import type { IHousePromotion } from 'utils/types/HousePromotion';
import type { IHouse } from 'utils/types/Houses';

const convertToDocumentData = (promotion: IHousePromotion): DocumentData => {
  return promotion as DocumentData;
};

export const UpdatePromotion2Firebase = async (
  collection: string,
  updatedData: IHousePromotion,
): Promise<void> => {
  const docRef = doc(firestore, collection, updatedData.id);

  const documentData = convertToDocumentData(updatedData);

  const docSnapshot = await getDoc(docRef);

  if (docSnapshot.exists()) {
    await updateDoc(docRef, documentData);
  } else {
    await setDoc(docRef, documentData);
  }
  // await updateDoc(docRef, documentData);
};

export const UpdateHouse2Firebase = async (
  collection: string,
  updatedData: IHouse,
  newID?: string,
): Promise<void> => {
  const updatedDataWithID: IHouse = {
    ...updatedData,
    id: newID ?? updatedData.id,
  };
  const docRef = doc(firestore, collection, updatedDataWithID.id);

  const documentData = updatedData as DocumentData;

  const docSnapshot = await getDoc(docRef);

  if (docSnapshot.exists()) {
    await updateDoc(docRef, documentData);
  } else {
    await setDoc(docRef, documentData);
  }
  // await updateDoc(docRef, documentData);
};

export const ActivateVoucher = async (
  collection: string,
  discountPercentage: number,
  houseID: string,
  promoID: string,
  promoImage: string,
): Promise<void> => {
  try {
    const docRef = doc(firestore, collection, houseID);

    await updateDoc(docRef, {
      discountPercentage,
      promotionID: promoID,
      promotionImage: promoImage,
    });
  } catch (error) {
    console.error('Error updating document:', error);
    throw error; // Rethrow the error to propagate it up the call stack
  }
};

export const DeactivateVoucher = async (
  collection: string,
  houseID: string,
): Promise<void> => {
  try {
    const docRef = doc(firestore, collection, houseID);

    await updateDoc(docRef, {
      promotionID: '',
    });
  } catch (error) {
    console.error('Error updating document:', error);
    throw error; // Rethrow the error to propagate it up the call stack
  }
};

export const UpdateDiscountPercentage = async (
  collection: string,
  discountPercentage: number,
  houseID: string,
): Promise<void> => {
  // eslint-disable-next-line no-magic-numbers
  const percent = discountPercentage * 100;
  try {
    const docRef = doc(firestore, collection, houseID);

    await updateDoc(docRef, {
      percent,
    });
  } catch (error) {
    console.error('Error updating document:', error);
    throw error; // Rethrow the error to propagate it up the call stack
  }
};

export const DeleteDocument = async (
  collection: string,
  documentId: string,
): Promise<void> => {
  try {
    const docRef = doc(firestore, collection, documentId);

    await deleteDoc(docRef);
  } catch (error) {
    console.error('Error deleting document:', error);
    throw error; // Rethrow the error to propagate it up the call stack
  }
};

export const createEmptyHousePromotion = (id: string): IHousePromotion => {
  return {
    availability: {},
    description: '',
    houseID: id,
    id: generateRandomId(),
    image: '',
    promotionID: '0',
    rules: {
      holidays: false,
      inPerson: false,
      observation: '',
      specialDates: false,
      tips: false,
    },
    title: '',
    originalPrice: 0,
    promotionalPrice: 0,
  };
};

export const getLegalTerms = async (): Promise<string> => {
  try {
    const docRef = doc(firestore, 'documents', 'legalTerms');
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      return docSnapshot.data().html;
    }
    console.error('Terms document not found');
    return '';
  } catch (error) {
    console.error('Error fetching terms:', error);
    return '';
  }
};

export const getCategories = async (): Promise<string[]> => {
  const styleCollection = collection(firestore, 'kitchenType');
  const q = query(styleCollection);

  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    const data = querySnapshot.docs[0].data();
    return data.type;
  }
  return [];
};

export const uploadHouseImage = async (file: File): Promise<string> => {
  if (!file) return '';

  const storageRef = ref(storage, `${auth.currentUser?.uid}/${file.name}`);

  await uploadBytes(storageRef, file);

  const publicImageUrl = await getDownloadURL(storageRef);

  return publicImageUrl;
};

export const deleteImage = async (imageUrl: string) => {
  try {
    // Create a reference to the file using the URL
    const imageRef = ref(storage, imageUrl);

    // Delete the file
    await deleteObject(imageRef);
  } catch (error) {
    console.error('Error deleting image:', (error as Error).message);
  }
};
