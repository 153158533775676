import React, { useCallback, useMemo } from 'react';

import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import LogoutIcon from '@mui/icons-material/Logout';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import EmailIcon from '@mui/icons-material/Email';

import { Link, useNavigate } from 'react-router-dom';

import { useAuth } from 'services/auth/authContext';

import Routes, { routesInfo } from 'AppRoutes/Routes';

import styles from './styles';

interface MenuDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

// const showRoutes = routesInfo;
const showRoutes = routesInfo.filter(
  route =>
    route.path !== Routes.LOGIN &&
    route.path !== Routes.SIGNUP &&
    route.path !== Routes.RECOVERY,
);

const MenuDrawer = ({
  isOpen,
  onClose,
}: MenuDrawerProps): React.JSX.Element => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  const handleLogout = useCallback(async () => {
    try {
      await auth.signOut(); // Sign the user out
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  }, [auth, navigate]);

  const routeList = useMemo(
    () => (
      <Box role="presentation" onClick={onClose} onKeyDown={onClose}>
        <List>
          {showRoutes.map((text, index) => (
            <Link style={styles.link} to={text.path} key={text.title}>
              <ListItem button key={text.title}>
                <ListItemIcon>
                  {index % 2 === 0 ? <MoveToInboxIcon /> : <EmailIcon />}
                </ListItemIcon>
                <ListItemText primary={text.title} />
              </ListItem>
            </Link>
          ))}
        </List>
        <Divider />
        <List>
          <ListItem button key="Logout" onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Sair" />
          </ListItem>
        </List>
      </Box>
    ),
    [handleLogout, onClose],
  );

  return (
    <Drawer open={isOpen} onClose={onClose}>
      {routeList}
    </Drawer>
  );
};

export default MenuDrawer;
