import { doc, getDoc, collection } from 'firebase/firestore';

import { firestore } from 'services/firebase/client';

export const validateHouse = async (id: string): Promise<boolean> => {
  const houseCollection = collection(firestore, 'houses');
  const docRef = doc(houseCollection, id);

  const docSnapshot = await getDoc(docRef);

  if (docSnapshot.exists()) {
    return true;
  }

  return false;
};
