import React, { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { Alert, Container, Fab } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

interface IImageLoaderProps {
  initialImage?: string;
  handleImage: (file: File) => void;
}

const ImageLoader = ({
  initialImage,
  handleImage,
}: IImageLoaderProps): React.JSX.Element => {
  const [image, setImage] = useState<string | undefined>(initialImage);
  const [error, setError] = useState(false);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // Check if the file has a valid extension (jpg or png)
      const allowedExtensions = ['jpg', 'jpeg', 'png'];
      const fileExtension = file.name.split('.').pop()?.toLowerCase();

      if (fileExtension && allowedExtensions.includes(fileExtension)) {
        handleImage(file);
        const reader = new FileReader();

        reader.onload = e => {
          setImage(e.target?.result as string);
        };

        reader.readAsDataURL(file);
      } else {
        setError(true);
      }
    }
  };

  const clearImage = () => {
    setImage('');
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const onAddImageClick = useCallback(() => {
    setError(false);
  }, []);

  return (
    <Container
      style={{
        flex: 1,
        flexDirection: 'column',
      }}
    >
      {error && (
        <Alert variant="filled" severity="error">
          <strong>Extensões aceitas: jpg, jpeg e png</strong>
        </Alert>
      )}
      <Container
        style={{
          // display: 'flex',
          // position: 'relative',
          marginBottom: '20px',
          marginTop: '20px',
        }}
      >
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          style={{ marginRight: 30 }}
          onClick={onAddImageClick}
        >
          Adicionar imagem
          <VisuallyHiddenInput type="file" onChange={handleImageChange} />
        </Button>
      </Container>
      <div style={{ textAlign: 'center' }}>
        <div style={{ maxWidth: '500px', marginBottom: '1rem' }}>
          {image && (
            <Container
              style={{
                position: 'relative',
                backgroundColor: 'red',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: 'block',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                }}
              >
                <img src={image} alt="Uploaded" style={{ maxWidth: '100%' }} />
                <Fab
                  size="medium"
                  color="primary"
                  aria-label="add"
                  onClick={clearImage}
                  style={{
                    marginRight: 0,
                    position: 'absolute',
                    top: '3%',
                    right: '4%',
                  }}
                >
                  <DeleteIcon />
                </Fab>
              </div>
            </Container>
          )}
        </div>
      </div>
    </Container>
  );
};

export default ImageLoader;
