import React from 'react';

// Importing Material UI components
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';

// import { green } from '@mui/material/colors';

import colors from 'ThemeProvider/colors';
import type { IFeedbacks } from 'utils/types/Vouchers';
import { Start } from 'assets/icons';

interface IVoucherCardProps {
  name: string;
  date: string;
  keyIndex: string;
  feedback?: IFeedbacks;
  handleModal: (feedback: IFeedbacks, openFlag: boolean) => void;
}

const VoucherCard = ({
  name,
  date,
  feedback,
  keyIndex,
  handleModal,
}: IVoucherCardProps): React.JSX.Element => {
  let average = 0;
  if (feedback) {
    average =
      (feedback.beverages +
        feedback.environment +
        feedback.food +
        feedback.service) /
      // eslint-disable-next-line no-magic-numbers
      4;
  }

  return (
    <Card
      sx={{
        maxWidth: 500,
        width: 500,
        backgroundColor: colors.yellowFestGourmet,
        marginBottom: 1,
      }}
      key={keyIndex}
    >
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: colors.bestBlack }} aria-label="user">
            {name[0] ? name[0].toUpperCase() : ''}
          </Avatar>
        }
        action={<IconButton aria-label="settings" />}
        title={name}
        subheader={`${date}`}
      />

      {feedback && (
        <CardActions>
          <Grid flexDirection="row" display="flex" alignItems="center">
            <Grid
              flexDirection="row"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flex={1}
            >
              <Typography variant="h5">{average}</Typography>
              <img src={Start} alt="" width="30%" height="30%" />
            </Grid>
            <Button
              size="medium"
              onClick={() => handleModal(feedback, true)}
              style={{ color: 'black', textDecoration: 'underline', flex: 2 }}
            >
              Ver Feedback
            </Button>
          </Grid>
        </CardActions>
      )}
    </Card>
  );
};

export default VoucherCard;
