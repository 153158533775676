import { v4 as uuidv4 } from 'uuid';

import type { AxiosRequestConfig } from 'axios';

import axios from 'axios';

import type { IAddress } from 'utils/types/Adress';

const getJwtToken = async (): Promise<string> => {
  const tokenUrl =
    'https://h-apigateway.conectagov.estaleiro.serpro.gov.br/oauth2/jwt-token';

  const requestBody = {
    // Include any necessary payload for token request
    // Example payload:
    // clientId: 'your_client_id',
    // clientSecret: 'your_client_secret',
  };

  try {
    const response = await axios.post(tokenUrl, requestBody);
    return response.data.access_token;
  } catch (error) {
    console.error('Error fetching JWT token:', error);
    throw error;
  }
};

export const getAddressData = async (cep: string): Promise<IAddress> => {
  try {
    const jwtToken = await getJwtToken();
    // Include the JWT token in the Authorization header
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };

    const response = await axios.get(
      `https://h-apigateway.conectagov.estaleiro.serpro.gov.br/api-cep/v1/consulta/cep/${cep}`,
      config,
    );

    return response.data as IAddress;
  } catch (error) {
    console.error('Error fetching address data:', error);
    throw error;
  }
};

export const slugify = (text: string): string => {
  return text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
};

export const generateRandomId = (): string => {
  // eslint-disable-next-line no-magic-numbers
  return uuidv4().substring(0, 20);
};

export const isCnpjValid = (cnpj: string): boolean => {
  // Remove non-digit characters
  const cleanedCnpj = cnpj.replace(/\D/g, '');

  // Check if the cleaned CNPJ has the correct length
  // eslint-disable-next-line no-magic-numbers
  if (cleanedCnpj.length !== 14) {
    return false;
  }

  // Calculate the CNPJ checksum
  const calcChecker1 = (str: string, weights: number[]): number => {
    return str
      .split('')
      .reduce(
        (acc, digit, index) => acc + parseInt(digit, 10) * weights[index],
        0,
      );
  };

  const calcChecker2 = (str: string, weights: number[]): number =>
    str
      .split('')
      .slice(1)
      .reduce(
        (acc, digit, index) => acc + parseInt(digit, 10) * weights[index + 1],
        0,
      );

  const checker1 = calcChecker1(
    // eslint-disable-next-line no-magic-numbers
    cleanedCnpj.slice(0, 12),

    // eslint-disable-next-line no-magic-numbers
    [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2],
  );

  const checker2 = calcChecker2(
    // eslint-disable-next-line no-magic-numbers
    cleanedCnpj.slice(0, 13),
    // eslint-disable-next-line no-magic-numbers
    [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2],
  );

  // eslint-disable-next-line no-magic-numbers
  const digit13 = 11 - (checker1 % 11);

  // eslint-disable-next-line no-magic-numbers
  const digit14 = 11 - (checker2 % 11);

  // eslint-disable-next-line no-magic-numbers
  const calculatedDigit13 = digit13 >= 10 ? 0 : digit13;
  // eslint-disable-next-line no-magic-numbers
  const calculatedDigit14 = digit14 >= 10 ? 0 : digit14;

  // Validate the last two digits
  return (
    // eslint-disable-next-line no-magic-numbers
    parseInt(cleanedCnpj.charAt(12), 10) === calculatedDigit13 &&
    // eslint-disable-next-line no-magic-numbers
    parseInt(cleanedCnpj.charAt(13), 10) === calculatedDigit14
  );
};
