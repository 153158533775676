import React from 'react';

// import { onAuthStateChanged } from 'firebase/auth';

import { Box, Container, Rating, Typography } from '@mui/material';

import Routes from 'AppRoutes/Routes';

import Header from 'components/Header';
import { useHouseData } from 'utils/contexts/houseContext';

// import { useAuth } from 'services/auth/authContext';

import HouseForm from 'components/HouseForm';

import type { IHouse } from 'utils/types/Houses';

import styles from './styles';

const Home = (): React.JSX.Element => {
  const houseData = useHouseData();
  // const { auth } = useAuth();

  // useEffect(() => {
  //   onAuthStateChanged(auth, user => {
  //     if (user) {
  //       // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //       const { uid } = user;
  //     }
  //   });
  // }, [auth, houseData]);

  return (
    <section>
      <Header route={Routes.HOME} />
      <Container
        style={{
          paddingLeft: 40,
          paddingTop: 40,
          flex: 2,
        }}
      >
        <Container
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            display: 'flex',
          }}
        >
          <div
            style={{
              justifyContent: 'flex-start',
              display: 'flex',
              flex: 1,
            }}
          >
            <img
              src={houseData?.houseData?.thumbnailURL}
              width={80}
              alt=""
              style={{
                borderRadius: '50%', // Makes the image circular
                // border: '1px solid #121212', // Adds a 2px border with black color
              }}
            />
          </div>
          <Container
            style={{
              flexDirection: 'column',
              display: 'flex',
              flex: 12,
            }}
          >
            <Typography variant="h3" style={styles.title}>
              {houseData?.houseData?.name}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginBottom: '20px',
              }}
            >
              <Typography variant="h6">
                {`${houseData?.houseData?.rating.average}/${houseData?.houseData?.rating.evaluations}`}
              </Typography>
              <Rating
                name="read-only"
                value={houseData?.houseData?.rating.average}
                readOnly
                precision={0.5}
                size="large"
              />
            </Box>
          </Container>
        </Container>

        <HouseForm houseData={houseData?.houseData as IHouse} />
      </Container>
    </section>
  );
};

export default Home;
