const TranslatableRoutes = {
  ROOT: '/',
  LOGIN: '/login',
  HOME: '/home',
  VOUCHERS: '/vouchers',
  SIGNUP: '/signup',
  PROMOTIONS: '/promotions',
  RECOVERY: '/recovery',
  ACCOUNT: '/account',
} as const;

const Routes = {
  ...TranslatableRoutes,
  PREV_PAGE: -1,
  UNKNOWN_ROUTE: '*',
} as const;

const knownTranslatableRoutes = new Set<string>(
  Object.values(TranslatableRoutes),
);

export const isValidTranslatablePath = (
  path: string,
): path is TranslatablePath => knownTranslatableRoutes.has(path);

export type RoutesType = typeof Routes;
export type RouteName = keyof RoutesType;
export type RoutePath = RoutesType[RouteName];
export type TranslatablePath =
  (typeof TranslatableRoutes)[keyof typeof TranslatableRoutes];

export default Routes;

export const routesInfo = [
  { path: Routes.LOGIN, isPrivate: false, title: 'Login' },
  { path: Routes.HOME, isPrivate: true, title: 'Início' },
  { path: Routes.VOUCHERS, isPrivate: true, title: 'Vouchers' },
  { path: Routes.PROMOTIONS, isPrivate: true, title: 'Promoções' },
  { path: Routes.SIGNUP, isPrivate: false, title: 'Cadastre-se' },
  { path: Routes.RECOVERY, isPrivate: false, title: 'Recuperar senha' },
  { path: Routes.ACCOUNT, isPrivate: true, title: 'Minha conta' },
];

export interface NavigationParams {
  [Routes.ROOT]?: never;
  [Routes.LOGIN]?: never;
  [Routes.HOME]?: never;
  [Routes.VOUCHERS]?: never;
  [Routes.SIGNUP]?: never;
  [Routes.PROMOTIONS]?: never;
}
