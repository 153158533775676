import type { UserCredential } from 'firebase/auth';
import { createUserWithEmailAndPassword } from 'firebase/auth';

import { auth } from './client';

export const signupWithEmailAndPassword = async (
  email: string,
  password: string,
): Promise<UserCredential | Error> => {
  const response = createUserWithEmailAndPassword(auth, email, password)
    .then(userCredential => {
      // Signed up
      return userCredential;
    })
    .catch(error => {
      return error as Error;
    });

  return response;
};
