import sx from 'utils/createSx';
import color from 'ThemeProvider/colors';

export default sx({
  title: {
    fontVariant: 'h1',
    paddingRight: 30,
    color: color.bestBlack,
  },
});
