import type { SetStateAction, Dispatch } from 'react';
import React, { useState, useEffect, useCallback } from 'react';

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CardActions,
  CircularProgress,
  Container,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';

// import { useAuth } from 'services/auth/authContext';

import type {
  IAvailability,
  IHousePromotion,
} from 'utils/types/HousePromotion';
import AvailabilityEditor from 'components/AvailabilityEditor';
import ImageLoader from 'components/ImageLoader';
import { useHouseData } from 'utils/contexts/houseContext';

import {
  ActivateVoucher,
  DeactivateVoucher,
  UpdateDiscountPercentage,
  UpdatePromotion2Firebase,
  uploadHouseImage,
} from 'services/firebase/updates';

// import PriceMaskedTextField from 'components/PriceMaskedTextField';

const MINIMUM_DISCOUNT = 19.9;

interface IPromotionFormProps {
  promotionData: IHousePromotion;
  onSave: (promotion: IHousePromotion) => void;
  onClose: Dispatch<SetStateAction<IHousePromotion | null>>;
}

const PromotionForm: React.FC<IPromotionFormProps> = ({
  promotionData,
  onSave,
  onClose,
}) => {
  const [selectedPromotion, setSelectedPromotion] = useState<IHousePromotion>({
    ...promotionData,
  });

  // const [newImage, setNewImage] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  const [isOriginalPriceError, setIsOriginalPriceError] = useState(false);
  const [isPromotionalPriceError, setIsPromotionalPriceError] = useState(false);
  const [isDiscountInvalid, setIsDiscountInvalid] = useState(false);
  const houseData = useHouseData();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // Add event listener only on the client side
    if (typeof window !== 'undefined') {
      const handleBeforeUnload = (e: BeforeUnloadEvent) => {
        if (!isFormSaved()) {
          e.returnValue =
            'You have unsaved changes. Are you sure you want to leave?';
        }
      };

      // eslint-disable-next-line no-undef
      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        // eslint-disable-next-line no-undef
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, []);

  const isFormSaved = () => {
    // console.log(`PromotionData: ${JSON.stringify(promotionData, null, 2)}`);
    return JSON.stringify(selectedPromotion) === JSON.stringify(promotionData);
  };

  const handleNewImage = useCallback(async (file: File) => {
    const publicImageUrl = await uploadHouseImage(file);

    setSelectedPromotion(prevData => ({
      ...prevData,
      image: publicImageUrl, // Update the image URL
    }));
  }, []);

  const checkPrices = useCallback((): boolean => {
    if (!selectedPromotion.originalPrice) {
      setIsOriginalPriceError(true);
      return false;
    }
    if (!selectedPromotion.promotionalPrice) {
      setIsPromotionalPriceError(true);
      return false;
    }

    if (selectedPromotion.promotionalPrice >= selectedPromotion.originalPrice) {
      setIsOriginalPriceError(true);
      setIsPromotionalPriceError(true);
      return false;
    }

    const discountPrice =
      selectedPromotion.originalPrice - selectedPromotion.promotionalPrice;

    if (discountPrice < MINIMUM_DISCOUNT) {
      setIsDiscountInvalid(true);
      return false;
    }

    return true;
  }, [selectedPromotion.originalPrice, selectedPromotion.promotionalPrice]);

  const handleSave = useCallback(async () => {
    if (!checkPrices()) {
      return;
    }

    if (!houseData?.houseData?.id) {
      return;
    }
    setIsLoading(true);
    setSelectedPromotion(prevSelectedPromotion => ({
      ...prevSelectedPromotion,
      // Update other properties if needed
    }));
    // if the user changed the image, need to upload it and get the URL

    const discountPercentage =
      1 - selectedPromotion.promotionalPrice / selectedPromotion.originalPrice;

    onSave(selectedPromotion);
    await UpdatePromotion2Firebase('promotions', selectedPromotion);
    await houseData.updateHouseData(houseData.houseData.id);
    await UpdateDiscountPercentage(
      'houses',
      discountPercentage,
      houseData.houseData.id,
    );
    setIsLoading(false);
  }, [checkPrices, houseData, onSave, selectedPromotion]);

  const handleActivateVoucher = useCallback(async () => {
    if (!houseData?.houseData?.id) {
      return;
    }

    const discountPercentage =
      1 - selectedPromotion.promotionalPrice / selectedPromotion.originalPrice;

    await ActivateVoucher(
      'houses',
      discountPercentage,
      houseData?.houseData?.id,
      selectedPromotion.id,
      selectedPromotion.image,
    );
    onSave(selectedPromotion);
    await houseData.updateHouseData(houseData.houseData.id);
  }, [houseData, onSave, selectedPromotion]);

  const handleDeactivateVoucher = useCallback(async () => {
    if (!houseData?.houseData?.id) {
      return;
    }

    await DeactivateVoucher('houses', houseData?.houseData?.id);
    onSave(selectedPromotion);
    await houseData.updateHouseData(houseData.houseData.id);
  }, [houseData, onSave, selectedPromotion]);

  const handleSwitchChange = useCallback((rules: string) => {
    switch (rules) {
      case 'tips':
        setSelectedPromotion(prevData => ({
          ...prevData,
          rules: {
            ...prevData.rules,
            tips: !prevData.rules.tips,
          },
        }));
        break;
      case 'specialDates':
        setSelectedPromotion(prevData => ({
          ...prevData,
          rules: {
            ...prevData.rules,
            specialDates: !prevData.rules.specialDates,
          },
        }));
        break;
      case 'holidays':
        setSelectedPromotion(prevData => ({
          ...prevData,
          rules: {
            ...prevData.rules,
            holidays: !prevData.rules.holidays,
          },
        }));
        break;
      default:
        console.error('Something went wrong');
    }

    // Call the onUpdate function to update Firebase
    // onUpdate(availabilityData);
  }, []);

  const formatCurrency = (value: string): string => {
    // Convert the string to a number (assuming the input is in cents)

    if (value === 'NaN') {
      return '0,00';
    }
    const numericValue = parseFloat(value);

    const formattedCurrency = numericValue.toFixed(2);

    return formattedCurrency.replace('.', ',');
  };

  const handlePrices = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      console.log(`Name: ${name} - Value: ${value}`);

      if (name === 'promotionalPrice' || name === 'originalPrice') {
        setIsDiscountInvalid(false);
      }

      setIsPromotionalPriceError(false);
      setIsOriginalPriceError(false);

      const numericString = value.replace(',', '.').replace(/[^\d,]/g, '');

      // eslint-disable-next-line no-magic-numbers
      const numericValue = parseFloat(numericString.replace(',', '.')) / 100;

      setSelectedPromotion({
        ...selectedPromotion,
        [name]: numericValue,
      });
    },
    [selectedPromotion],
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      // Split the name into nested property parts
      const nestedProps = name.split('.');

      // If there are nested properties, update them
      if (nestedProps.length === 2) {
        const [parentProp, childProp] = nestedProps;

        if (parentProp === 'rules') {
          switch (childProp) {
            case 'observation':
              setSelectedPromotion({
                ...selectedPromotion,
                rules: {
                  ...selectedPromotion.rules,
                  observation: value,
                },
              });
              break;
            default:
              console.error('Something went wrong');
          }
        }
      }
      setSelectedPromotion({
        ...selectedPromotion,
        [name]: value,
      });
    },
    [selectedPromotion],
  );

  const handleComponentClose = () => {
    onClose(null);
  };

  const onUpdateAvailability = useCallback(
    (availabilityData: IAvailability) => {
      setSelectedPromotion(prevData => ({
        ...prevData,
        availability: availabilityData, // Update the image URL
      }));
    },
    [],
  );

  const getPercentage = useCallback(() => {
    const perc = (
      (1 -
        selectedPromotion.promotionalPrice / selectedPromotion.originalPrice) *
      // eslint-disable-next-line no-magic-numbers
      100
    ).toFixed(2);
    return perc;
  }, [selectedPromotion.originalPrice, selectedPromotion.promotionalPrice]);

  const getDiscount = useCallback(() => {
    let discount =
      selectedPromotion.originalPrice - selectedPromotion.promotionalPrice;

    if (discount < 0 || discount === selectedPromotion.originalPrice) {
      discount = 0;
    }

    return discount.toFixed(2);
  }, [selectedPromotion.originalPrice, selectedPromotion.promotionalPrice]);

  return (
    <Container
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {isOriginalPriceError && (
        <Alert severity="error">
          <AlertTitle>Atenção</AlertTitle>
          <strong>Preço original inválido!</strong>
        </Alert>
      )}
      {isPromotionalPriceError && (
        <Alert severity="error">
          <AlertTitle>Atenção</AlertTitle>
          <strong>Preço promocional inválido!</strong>
        </Alert>
      )}
      {isDiscountInvalid && (
        <Alert severity="error">
          <AlertTitle>Atenção</AlertTitle>
          <strong>O valor mínimo do desconto deve ser de R$ 19,90</strong>
        </Alert>
      )}
      {isLoading && <CircularProgress />}
      <Container
        style={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h4" />
        <CardActions>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={isFormSaved()}
          >
            SALVAR
          </Button>
          {promotionData.id === houseData?.houseData?.promotionID ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleDeactivateVoucher}
            >
              PARAR PROMOÇÃO
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleActivateVoucher}
            >
              ATIVAR PROMOÇÃO
            </Button>
          )}
          <Button variant="outlined" onClick={handleComponentClose}>
            FECHAR
          </Button>
        </CardActions>
      </Container>
      <Container style={{ marginTop: 20 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Título"
            name="title"
            variant="outlined"
            value={selectedPromotion.title}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box mt={5}>
            <TextField
              fullWidth
              label="Descrição"
              name="description"
              variant="outlined"
              value={selectedPromotion.description}
              onChange={handleChange}
            />
          </Box>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Box mt={5}>
              <TextField
                error={isOriginalPriceError}
                fullWidth
                label="Valor original (R$)"
                name="originalPrice"
                variant="outlined"
                // value={selectedPromotion.originalPrice.toString()}
                value={formatCurrency(
                  selectedPromotion.originalPrice.toString(),
                )}
                onChange={handlePrices}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box mt={5}>
              <TextField
                error={isPromotionalPriceError}
                fullWidth
                label="Valor promocional (R$)"
                name="promotionalPrice"
                variant="outlined"
                value={formatCurrency(
                  selectedPromotion.promotionalPrice.toString(),
                )}
                onChange={handlePrices}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box mt={5}>
              <TextField
                variant="outlined"
                label="% Desconto"
                disabled
                value={getPercentage() !== 'NaN' ? getPercentage() : ''}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box mt={5}>
              <TextField
                variant="outlined"
                label="R$ Desconto"
                disabled
                value={getDiscount() !== 'NaN' ? getDiscount() : ''}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          <Grid item xs={12}>
            <Typography variant="h5">Regras</Typography>
            <Typography variant="body2">
              Marque as opções válidas para sua promoção
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <label>Válido para feriados?</label>
              </Grid>
              <Grid item xs={6}>
                <Switch
                  checked={selectedPromotion.rules.holidays}
                  onChange={() => handleSwitchChange('holidays')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <label>Válido em datas especiais?</label>
                <Typography variant="subtitle2">
                  (Dia das Mães, Dia dos Namorados, etc)
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Switch
                  checked={selectedPromotion.rules.specialDates}
                  onChange={() => handleSwitchChange('specialDates')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              {/* <Grid item xs={6}>
                <label>Presencial</label>
              </Grid>
              <Grid item xs={6}>
                <Switch
                  checked={selectedPromotion.rules.inPerson}
                  onChange={() => {
                    console.log(`Teste`);
                  }}
                />
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <label>Inclui taxa de serviço/gorjetas?</label>
              </Grid>
              <Grid item xs={6}>
                <Switch
                  checked={selectedPromotion.rules.tips}
                  name="rules.tips"
                  onChange={() => handleSwitchChange('tips')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Outras observações"
                name="rules.observation"
                variant="outlined"
                value={selectedPromotion.rules.observation}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          <Grid item xs={12}>
            <Typography variant="h5">Disponibilidade</Typography>
            <Typography variant="body2">
              Marque os dias da semana e períodos para os quais seu voucher será
              válido
            </Typography>
            <AvailabilityEditor
              availability={selectedPromotion?.availability ?? {}}
              onUpdate={onUpdateAvailability}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          <Grid item xs={12}>
            <Typography variant="h5">Imagem da promoção</Typography>
            <ImageLoader
              initialImage={selectedPromotion?.image}
              handleImage={handleNewImage}
            />
          </Grid>
        </Grid>
      </Container>
      {/* Additional fields here */}
    </Container>
  );
};

export default PromotionForm;
