import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Button,
  CardActions,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from '@mui/material';

import Lottie from 'lottie-react';

import { Visibility, VisibilityOff } from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';

import Routes from 'AppRoutes/Routes';

import Header from 'components/Header';
import type { IHouse } from 'utils/types/Houses';
import {
  fetchCitiesByStateId,
  fetchStates,
  type ICity,
  type IState,
} from 'utils/data/fetchStates';

import { Instagram, Phone, Whatsapp } from 'assets/icons';
import { isCnpjValid } from 'utils/helpers';

import './styles.css';
import {
  getCategories,
  getLegalTerms,
  UpdateHouse2Firebase,
} from 'services/firebase/updates';
import { signupWithEmailAndPassword } from 'services/firebase/auth';
import { ChefsOnStove } from 'assets/animations';
import { useAuth } from 'services/auth/authContext';

const benefitsArray = [
  'pets',
  'kids',
  'internalArea',
  'parking',
  'outsideArea',
  'craftBeer',
];

const passwordRules = [
  '* A senha deve ter pelo menos 8 caracteres.',
  '* Deve conter pelo menos uma letra maiúscula.',
  '* Deve conter pelo menos uma letra minúscula.',
  '* Deve conter pelo menos um caractere numérico.',
  '* Deve conter pelo menos um caractere especial (por exemplo, !@#$%^&*(),.?":{}|<>).',
  '* Não deve conter espaços em branco.',
];

const PRICE_VALUES = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
};

const SignUp = (): React.JSX.Element => {
  const navigate = useNavigate();
  const { auth } = useAuth();

  const [formData, setFormData] = useState<IHouse>({
    corporateName: '',
    cnpj: '',
    name: '',
    style: '',
    address: '',
    UF: '',
    neighborhood: '',
    city: '',
    open: '',
    promotionID: '',
    discountPercentage: 0,
    promotionImage: '',
    zipcode: '',
    price: 1,
    type: [],
    benefits: {
      parking: false,
      kids: false,
      pets: false,
      internalArea: false,
      outsideArea: false,
      craftBeer: false,
    },
    status: true,
    id: '',
    rating: {
      evaluations: 0,
      average: 0,
    },
    instagram: '',
    whatsapp: '',
    phone: '',
    image: '', // Add your default image URL or handle it appropriately
    thumbnailURL: '', // Add your default thumbnail URL or handle it appropriately
  });
  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [term, setTerm] = useState('');
  const [categories, setCategories] = useState<string[]>([]);
  const [openSignupModal, setOpenSignupModal] = useState(false);
  const [loadingSignup, setLoadingSignup] = useState(false);
  const [completedSteps, setCompletedSteps] = useState<string[]>([]);

  const handleOpenModal = async () => {
    setOpenModal(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      const terms = await getLegalTerms();
      if (terms) {
        setTerm(terms);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, []);

  const onCloseSignupModal = useCallback(async () => {
    setOpenSignupModal(false);
    await auth.signOut(); // Sign the user out

    navigate('/login');
  }, [auth, navigate]);

  interface SignupModalProps {
    openSignupModal: boolean;
    onCloseSignupModal: () => void;
    loadingSignup: boolean;
    completedSteps: string[];
  }

  // const SignupModal = useMemo((): React.FC<SignupModalProps> => {
  const SignupModal: React.FC<SignupModalProps> = () => {
    return useMemo(() => {
      return (
        <Dialog open={openSignupModal} onClose={onCloseSignupModal}>
          <DialogContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {loadingSignup && <CircularProgress />}
            <Lottie
              animationData={ChefsOnStove}
              style={{ width: '200px', height: '200px' }}
            />
            <Typography variant="h5" style={{ marginTop: 20 }}>
              Bem vindo ao Club Gourmet Fest!
            </Typography>
            <Typography
              variant="h6"
              style={{ marginTop: 20, alignSelf: 'center' }}
            >
              Recebemos o seu cadastro e já começamos os preparativos.
            </Typography>
            <Typography variant="h6">
              Em breve nossa equipe entrará em contato com você.
            </Typography>
          </DialogContent>
        </Dialog>
      );
    }, []);
  };

  // const SignupModal: React.FC<SignupModalProps> = () => {
  //   return useMemo(() => {
  //     return (
  //       <Dialog open={openSignupModal} onClose={onCloseSignupModal}>
  //         <DialogTitle>Signup Flow</DialogTitle>
  //         <DialogContent>
  //           <List>
  //             <ListItem>
  //               <ListItemIcon>
  //                 {completedSteps.includes('User Created') ? (
  //                   <CheckIcon color="primary" />
  //                 ) : (
  //                   <IconButton disabled>1</IconButton>
  //                 )}
  //               </ListItemIcon>
  //               <ListItemText primary="Usuário criado" />
  //             </ListItem>
  //             <ListItem>
  //               <ListItemIcon>
  //                 {completedSteps.includes('House Added') ? (
  //                   <CheckIcon color="primary" />
  //                 ) : (
  //                   <IconButton disabled>2</IconButton>
  //                 )}
  //               </ListItemIcon>
  //               <ListItemText primary="Restaurante criado" />
  //             </ListItem>
  //           </List>
  //         </DialogContent>
  //       </Dialog>
  //     );
  //   }, []);
  // };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setCategories(await getCategories());
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, []);

  const fetchCities = useCallback(async (stateId: string) => {
    const response = await fetchCitiesByStateId(stateId);
    if (response) {
      setCities(response);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchStates();
      if (response) {
        setStates(response);
        await fetchCities(formData.UF);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, []);

  const cleanStates = useCallback(() => {
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setFormData({
      corporateName: '',
      cnpj: '',
      name: '',
      style: '',
      address: '',
      UF: '',
      neighborhood: '',
      city: '',
      open: '',
      discountPercentage: 0,
      promotionImage: '',
      promotionID: '',
      zipcode: '',
      price: 1,
      type: [],
      benefits: {
        parking: false,
        kids: false,
        pets: false,
        internalArea: false,
        outsideArea: false,
        craftBeer: false,
      },
      status: false,
      id: '',
      rating: {
        evaluations: 0,
        average: 0,
      },
      instagram: '',
      whatsapp: '',
      phone: '',
      image: '', // Add your default image URL or handle it appropriately
      thumbnailURL: '', // Add your default thumbnail URL or handle it appropriately
    });
  }, []);

  const handleOnSave = useCallback(async () => {
    const isValid = isCnpjValid(formData.cnpj);

    if (!isValid) {
      console.error('Invalid CNPJ format');
    }

    // Open the Modal
    setOpenSignupModal(true);
    setLoadingSignup(true);

    try {
      const response = await signupWithEmailAndPassword(email, password);

      if (response instanceof Error) {
        setLoadingSignup(false);
        return;
      }

      setFormData({
        ...formData,
        id: response.user.uid,
      });

      setCompletedSteps([...completedSteps, 'User Created']);

      // Step 2: Add house to Firestore

      await UpdateHouse2Firebase('houses', formData, response.user.uid);
      setCompletedSteps([...completedSteps, 'House Added']);
      cleanStates();
    } catch (error) {
      console.error('Signup failed:', error);
      setLoadingSignup(false);
    } finally {
      setLoadingSignup(false);
    }

    // await UpdateHouse2Firebase('houses', formData);
  }, [completedSteps, email, formData, password]);

  const validatePassword = (pass: string) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(pass);
    const hasLowerCase = /[a-z]/.test(pass);
    const hasNumber = /\d/.test(pass);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(pass);
    const noSpaces = !/\s/.test(pass);

    return (
      pass.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSpecialChar &&
      noSpaces
    );
  };

  const handleCheckboxChange = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setIsTermsAccepted(event.target.checked);
  };

  const handleLoginChange = useCallback(
    async (
      e: React.ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >,
    ) => {
      const { name, value } = e.target;

      if (name === 'email') {
        setEmail(value);
      } else if (name === 'password') {
        const isValidPassword = validatePassword(value);

        setIsPasswordValid(isValidPassword);
        setPassword(value);
      } else if (name === 'confirmPassword') {
        const isValidConfirmPassword = validatePassword(value);
        setIsConfirmPasswordValid(isValidConfirmPassword);

        setConfirmPassword(value);
      }
    },
    [],
  );

  const handleInputChange = useCallback(
    async (
      e: React.ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >,
    ) => {
      const { name, value } = e.target;

      if (e.target.type === 'checkbox') {
        const checkbox = e.target as HTMLInputElement; // Cast event.target to HTMLInputElement

        // Benefits
        if (benefitsArray.includes(name)) {
          // const updateBenefits = formData.benefits;
          const updateBenefits = { ...formData.benefits };

          switch (name) {
            case 'kids':
              updateBenefits.kids = checkbox.checked;
              break;
            case 'pets':
              updateBenefits.pets = checkbox.checked;
              break;
            case 'internalArea':
              updateBenefits.internalArea = checkbox.checked;
              break;
            case 'parking':
              updateBenefits.parking = checkbox.checked;
              break;
            case 'outsideArea':
              updateBenefits.outsideArea = checkbox.checked;
              break;
            case 'craftBeer':
              updateBenefits.craftBeer = checkbox.checked;
              break;
            default:
              console.error('Something went wrong!');
          }

          setFormData({
            ...formData,
            benefits: updateBenefits,
          });
          return;
        }

        // Types
        const updatedTypes = [...formData.type];

        if (checkbox.checked) {
          updatedTypes.push(name);
        } else {
          const index = updatedTypes.indexOf(name);
          if (index !== -1) {
            updatedTypes.splice(index, 1);
          }
        }
        setFormData({
          ...formData,
          type: updatedTypes,
        });
      } else if (e.target.type === 'radio') {
        // Handle radio button changes
        setFormData({
          ...formData,
          [name]: parseInt(value, 10), // Convert the value to an integer
        });
      } else {
        if (name === 'UF') {
          await fetchCities(value);
        }

        setFormData({
          ...formData,
          [name]: value,
        });
      }
    },
    [fetchCities, formData],
  );

  const handleTogglePasswordVisibility = useCallback(
    (field: string) => {
      if (field === 'password') {
        setShowPassword(!showPassword);
      } else if (field === 'confirmPassword') {
        setShowConfirmPassword(!showConfirmPassword);
      }
    },
    [showConfirmPassword, showPassword],
  );

  return (
    <section>
      <Header route={Routes.SIGNUP} hasBackButton />
      <Container
        style={{
          paddingLeft: 40,
          paddingTop: 40,
          flex: 2,
        }}
      >
        <Container
          style={{
            flexDirection: 'row',
            display: 'flex',
            flex: 3,
            marginLeft: -30,
          }}
        >
          <Typography variant="h3">Cadastre sua casa</Typography>
          <Container
            style={{
              flex: 9,
              flexDirection: 'column',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            <Typography variant="h4" />
            <CardActions>
              <Button
                variant="contained"
                onClick={handleOnSave}
                disabled={!isTermsAccepted}
              >
                SALVAR
              </Button>
            </CardActions>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isTermsAccepted}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Ao clicar você concorda com os"
              />
            </FormGroup>
            <Button
              size="small"
              onClick={handleOpenModal}
              style={{
                color: 'black',
                textDecoration: 'underline',
                flex: 1,
                fontSize: 11,
              }}
            >
              Termos de uso e política de privacidade
            </Button>
          </Container>
        </Container>

        <Dialog open={openModal} onClose={handleCloseModal}>
          <DialogTitle> Termos de uso e política de privacidade</DialogTitle>
          <DialogContent>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{ __html: term }}
            />
          </DialogContent>
        </Dialog>
        <SignupModal
          openSignupModal={openSignupModal}
          onCloseSignupModal={onCloseSignupModal}
          loadingSignup={loadingSignup}
          completedSteps={completedSteps}
        />
        <div className="section-title">
          <p>LOGIN</p>
        </div>
        <Container style={{ marginBottom: 30 }}>
          <List disablePadding style={{ margin: 0, padding: 0 }}>
            {passwordRules.map((rule, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem key={index} style={{ margin: 0, padding: 0 }}>
                <ListItemText
                  primaryTypographyProps={{ variant: 'subtitle2' }}
                  primary={rule}
                />
              </ListItem>
            ))}
          </List>
        </Container>

        <Container style={{ marginBottom: 50 }}>
          <div className="row-email">
            <Container
              style={{
                flexDirection: 'column',
              }}
            >
              <Typography>*E-mail:</Typography>
              <FormControl variant="outlined" fullWidth>
                {/* <InputLabel htmlFor="password">Password</InputLabel> */}
                <OutlinedInput
                  id="email"
                  name="email"
                  type="text"
                  value={email}
                  onChange={handleLoginChange}
                  endAdornment={<InputAdornment position="end" />}
                  label=""
                />
              </FormControl>
            </Container>

            <Container
              style={{
                flexDirection: 'column',
              }}
            >
              <Typography>*Senha:</Typography>
              <FormControl
                variant="outlined"
                error={!isPasswordValid && password.length !== 0}
              >
                <OutlinedInput
                  id="password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={handleLoginChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          handleTogglePasswordVisibility('password')
                        }
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label=""
                />
              </FormControl>
            </Container>
            <Container
              style={{
                flexDirection: 'column',
              }}
            >
              <Typography>*Confirme a senha:</Typography>
              <FormControl
                variant="outlined"
                error={!isConfirmPasswordValid && password.length !== 0}
              >
                {/* <InputLabel htmlFor="password">Password</InputLabel> */}
                <OutlinedInput
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={handleLoginChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          handleTogglePasswordVisibility('confirmPassword')
                        }
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label=""
                />
              </FormControl>
            </Container>
          </div>
        </Container>
        <div>
          <form>
            <div className="block-margin">
              <label htmlFor="name">*Razão social:</label>
              <input
                type="text"
                id="corporateName"
                name="corporateName"
                value={formData.corporateName}
                onChange={handleInputChange}
                className="custom-input"
              />
            </div>
            <div className="block-margin">
              <label htmlFor="name">*CNPJ:</label>
              <input
                type="text"
                id="cnpj"
                name="cnpj"
                value={formData.cnpj}
                onChange={handleInputChange}
                className="custom-input"
              />
            </div>
            <div className="row">
              <div className="input-name">
                <label htmlFor="name">*Nome do estabelecimento:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>

              <div className="block-margin">
                <label htmlFor="category">*Estilo de culinária:</label>
                <select
                  id="category"
                  name="style"
                  value={formData.style}
                  onChange={handleInputChange}
                  className="custom-input"
                >
                  <option value="">Selecione uma categoria</option>
                  {categories.map(category => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="container">
              <div className="section-title">
                <p>LOCALIZAÇÃO</p>
              </div>
              <div className="row">
                <div className="block-margin">
                  <label htmlFor="name">*Endereço:</label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    className="custom-input"
                  />
                </div>
                <div className="block-margin">
                  <label htmlFor="name">*UF:</label>
                  <select
                    id="UF"
                    name="UF"
                    value={formData.UF}
                    onChange={handleInputChange}
                    className="custom-input"
                  >
                    {states.map(state => (
                      <option key={state.sigla} value={state.sigla}>
                        {state.sigla}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="block-margin">
                  <label htmlFor="name">*Bairro:</label>
                  <input
                    type="text"
                    id="neighborhood"
                    name="neighborhood"
                    value={formData.neighborhood}
                    onChange={handleInputChange}
                    className="custom-input"
                  />
                </div>
                <div className="block-margin">
                  <label htmlFor="name">*Cidade:</label>
                  <select
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    className="custom-input"
                  >
                    {cities.map(city => (
                      <option key={city.nome} value={city.nome}>
                        {city.nome}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="zipcode-row">
                  <div className="block-margin">
                    <label htmlFor="name">*CEP:</label>
                    <input
                      type="text"
                      id="zipcode"
                      name="zipcode"
                      value={formData.zipcode}
                      onChange={handleInputChange}
                      className="custom-input"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="section-title">
              <div>PERFIL DO RESTAURANTE</div>
            </div>
            <div className="row-profile">
              <div>
                <p className="section-subtitle">Perfil de preço:</p>
                <div className="radio-group">
                  <label className="checkbox-label">
                    <input
                      type="radio"
                      name="price"
                      value="1"
                      checked={formData.price === PRICE_VALUES.ONE}
                      onChange={handleInputChange}
                    />
                    $
                  </label>
                  <label className="checkbox-label">
                    <input
                      type="radio"
                      name="price"
                      value="2"
                      checked={formData.price === PRICE_VALUES.TWO}
                      onChange={handleInputChange}
                    />
                    $$
                  </label>
                  <label className="checkbox-label">
                    <input
                      type="radio"
                      name="price"
                      value="3"
                      checked={formData.price === PRICE_VALUES.THREE}
                      onChange={handleInputChange}
                    />
                    $$$
                  </label>
                  <label className="checkbox-label">
                    <input
                      type="radio"
                      name="price"
                      value="4"
                      checked={formData.price === PRICE_VALUES.FOUR}
                      onChange={handleInputChange}
                    />
                    $$$$
                  </label>
                </div>
              </div>
              <div>
                <p className="section-subtitle">Serve quais refeições?</p>
                <div className="form-group">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name="Café da manhã"
                      checked={formData.type?.includes('Café da manhã')}
                      onChange={handleInputChange}
                    />
                    Café da manhã
                  </label>
                </div>
                <div className="form-group">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name="Brunch"
                      checked={formData.type?.includes('Brunch')}
                      onChange={handleInputChange}
                    />
                    Brunch
                  </label>
                </div>
                <div className="form-group">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name="Almoço"
                      checked={formData.type?.includes('Almoço')}
                      onChange={handleInputChange}
                    />
                    Almoço
                  </label>
                </div>
                <div className="form-group">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name="Jantar"
                      checked={formData.type?.includes('Jantar')}
                      onChange={handleInputChange}
                    />
                    Jantar
                  </label>
                </div>
              </div>
              <div>
                <p className="section-subtitle">Benefícios oferecidos:</p>
                <div className="form-group">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name="parking"
                      checked={formData.benefits.parking}
                      onChange={handleInputChange}
                    />
                    Estacionamento
                  </label>
                </div>
                <div className="form-group">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name="kids"
                      checked={formData.benefits.kids}
                      onChange={handleInputChange}
                    />
                    Área Kids
                  </label>
                </div>
                <div className="form-group">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name="pets"
                      checked={formData.benefits.pets}
                      onChange={handleInputChange}
                    />
                    Pet friendly
                  </label>
                </div>
                <div className="form-group">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name="internalArea"
                      checked={formData.benefits.internalArea}
                      onChange={handleInputChange}
                    />
                    Área interna
                  </label>
                </div>
              </div>
              <div>
                <p className="section-subtitle">Contatos:</p>
                <div className="row-container">
                  <img className="contacts" src={Instagram} alt="" width={30} />
                  <input
                    type="text"
                    id="instagram"
                    name="instagram"
                    value={formData.instagram}
                    onChange={handleInputChange}
                    className="custom-input"
                  />
                </div>
                <div className="row-container">
                  <img className="contacts" src={Whatsapp} alt="" width={30} />
                  <input
                    type="text"
                    id="whatsapp"
                    name="whatsapp"
                    value={formData.whatsapp}
                    onChange={handleInputChange}
                    className="custom-input"
                    placeholder="(XX) XXXXX-XXX"
                  />
                </div>
                <div className="row-container">
                  <img className="contacts" src={Phone} alt="" width={30} />
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    className="custom-input"
                    placeholder="(XX) XXXXX-XXX"
                  />
                </div>
              </div>
            </div>
            {/* <div className="row-50">
              <div>
                <Typography variant="h5">Imagem de capa:</Typography>
                <Typography variant="body2" style={{ marginLeft: 1 }}>
                  Imagem da fachada ou do interior da casa
                </Typography>
                <div className="row-container">
                  <ImageLoader
                    initialImage={formData.image}
                    handleImage={() => {}}
                  />
                  {isLoading && (
                    <div className="loading-overlay">
                      <div className="spinner" />
                    </div>
                  )}
                </div>
              </div>
              <div>
                <Typography variant="h5">Logomarca da casa:</Typography>
                <div className="thumbnail-container">
                  <ImageLoader
                    initialImage={formData.thumbnailURL}
                    handleImage={() => {}}
                  />
                </div>
              </div>
            </div> */}
          </form>
        </div>
      </Container>
    </section>
  );
};

export default SignUp;
