/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

import { Alert, Button, Container, TextField, Typography } from '@mui/material';

import { sendPasswordResetEmail } from 'firebase/auth';

import { auth } from 'services/firebase/client';
import Header from 'components/Header';
import Routes from 'AppRoutes/Routes';

const RecoveryPassword = (): React.JSX.Element => {
  const [email, setEmail] = useState('');
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleEmailChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setEmail(e.target.value);
    setIsInvalidEmail(false);
    setEmailSent(false);
  };

  const handleSendClick = async () => {
    // Call your Firebase endpoint for password recovery here
    if (email === '') {
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setEmailSent(true);
      setEmail('');
    } catch (error) {
      setIsInvalidEmail(true);
    }
  };

  return (
    <>
      <Header route={Routes.RECOVERY} hasBackButton />
      <Container component="main" maxWidth="xs" style={{ marginTop: 50 }}>
        <div>
          <Typography component="h1" variant="h5" style={{ marginBottom: 10 }}>
            Esqueceu sua senha?
          </Typography>
          <Typography variant="body1" style={{ marginBottom: 40 }}>
            Informe o e-mail de seu cadastro. Enviaremos um link para que você
            possa alterar sua senha.
          </Typography>
          <form noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-mail"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={handleEmailChange}
              style={{ marginBottom: 20 }}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              // className={classes.submit}
              onClick={handleSendClick}
            >
              Enviar
            </Button>
          </form>
        </div>
        {isInvalidEmail && (
          <Alert variant="filled" severity="error" style={{ marginTop: 50 }}>
            <strong>E-mail inválido!</strong>
          </Alert>
        )}
        {emailSent && (
          <Alert variant="filled" severity="success" style={{ marginTop: 50 }}>
            <strong>E-mail enviado com sucesso!</strong>
          </Alert>
        )}
      </Container>
    </>
  );
};

export default RecoveryPassword;
