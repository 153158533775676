/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';

import { useNavigate } from 'react-router-dom';

import {
  Alert,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import { Visibility, VisibilityOff } from '@mui/icons-material';

import { auth } from 'services/firebase/client';

import Routes from 'AppRoutes/Routes';

import Header from 'components/Header';
import { useHouseData } from 'utils/contexts/houseContext';
import { validateHouse } from 'services/firebase/validateHouse';

const Login = (): React.JSX.Element => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [credentialError, setCredentialError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const houseData = useHouseData();

  const onLogin = useCallback(
    (e: { preventDefault: () => void }) => {
      e.preventDefault();
      signInWithEmailAndPassword(auth, email, password)
        .then(async userCredential => {
          // Signed in
          const { user } = userCredential;
          const isHouse = await validateHouse(user.uid);

          if (!isHouse) {
            setCredentialError(true);
            return;
          }

          await houseData?.updateHouseData(user.uid);
          navigate('/home');
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(error => {
          // const errorCode = error.code;
          // const errorMessage = error.message;
          // console.log(errorCode, errorMessage);
          setCredentialError(true);
        });
    },
    [email, houseData, navigate, password],
  );

  const onSignUp = useCallback(() => {
    navigate('/signup');
  }, [navigate]);

  const onRecoveryPassword = useCallback(() => {
    navigate('/recovery');
  }, [navigate]);

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '80vh',
    justifyContent: 'center',
  };

  const handleTogglePasswordVisibility = useCallback(
    (field: string) => {
      if (field === 'password') {
        setShowPassword(!showPassword);
      }
    },
    [showPassword],
  );

  const handleLoginChange = useCallback(
    (e: { target: { value: React.SetStateAction<string> } }) => {
      setPassword(e.target.value);
      setCredentialError(false);
    },
    [],
  );

  return (
    <>
      <Header route={Routes.LOGIN} />

      <Container component="main" maxWidth="xs" sx={containerStyle}>
        <Paper elevation={3}>
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          > */}
          <Container
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <form style={{ width: '100%', marginTop: 8 }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="E-mail"
                name="username"
                autoComplete="username"
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                  setCredentialError(false);
                }}
              />
              <FormControl
                variant="outlined"
                // error={!isPasswordValid && password.length !== 0}
                style={{ marginTop: 20, width: '100%' }}
              >
                <InputLabel htmlFor="password">Senha</InputLabel>
                <OutlinedInput
                  id="password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={handleLoginChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          handleTogglePasswordVisibility('password')
                        }
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Senha"
                />
              </FormControl>

              <Button
                type="button"
                fullWidth
                variant="contained"
                onClick={onLogin}
                style={{ marginTop: 20 }}
              >
                ENTRAR
              </Button>
              <Link
                component="button"
                variant="body2"
                style={{ marginTop: '1rem', marginBottom: '2rem' }}
                onClick={onRecoveryPassword}
              >
                Esqueceu a senha?
              </Link>
            </form>
            <Grid
              flexDirection="column"
              display="flex"
              alignItems="center"
              justifyItems="center"
            >
              <Typography variant="body1">
                Ainda não faz parte do nosso Clube?
              </Typography>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="secondary"
                onClick={onSignUp}
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                Cadastre-se
              </Button>
            </Grid>
            {/* </div> */}
          </Container>
        </Paper>
      </Container>
      {credentialError && (
        <Alert variant="filled" severity="error">
          <strong>E-mail/senha inválidos!</strong>
        </Alert>
      )}
    </>
  );
};

export default Login;
