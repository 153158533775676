import React from 'react';

import { Box, Grid, Rating, Typography } from '@mui/material';

import type { IFeedbacks } from 'utils/types/Vouchers';

interface IFeedbackModalProps {
  feedback?: IFeedbacks;
}

const FeedbackModal = ({
  feedback,
}: IFeedbackModalProps): React.JSX.Element => {
  if (!feedback) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
  const average =
    (feedback.beverages +
      feedback.environment +
      feedback.food +
      feedback.service) /
    // eslint-disable-next-line no-magic-numbers
    4;

  return (
    <Box m={2}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        style={{ marginBottom: 30 }}
      >
        {average}
      </Typography>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        flexDirection="column"
        alignContent="center"
      >
        <Grid flexDirection="row" display="flex">
          <Typography variant="h6" gutterBottom style={{ width: 100 }}>
            Comida:
          </Typography>
          <Rating
            name="food"
            value={feedback?.food}
            readOnly
            precision={0.5}
            size="large"
          />
        </Grid>
        <Grid flexDirection="row" display="flex">
          <Typography variant="h6" gutterBottom style={{ width: 100 }}>
            Bebidas:
          </Typography>
          <Rating
            name="beverages"
            value={feedback?.beverages}
            readOnly
            precision={0.5}
            size="large"
            style={{ flex: 3 }}
          />
        </Grid>

        <Grid flexDirection="row" display="flex">
          <Typography variant="h6" gutterBottom style={{ width: 100 }}>
            Serviço:
          </Typography>
          <Rating
            name="service"
            value={feedback?.service}
            readOnly
            precision={0.5}
            size="large"
            style={{ flex: 3 }}
          />
        </Grid>
        <Grid flexDirection="row" display="flex">
          <Typography variant="h6" gutterBottom style={{ width: 100 }}>
            Ambiente:
          </Typography>
          <Rating
            name="env"
            value={feedback?.environment}
            readOnly
            precision={0.5}
            size="large"
            style={{ flex: 3 }}
          />
        </Grid>
        <Grid container flexDirection="column" paddingTop={5}>
          <Typography variant="h6" gutterBottom style={{ width: 100 }}>
            Mensagem:
          </Typography>
          <Typography variant="body1" gutterBottom>
            {feedback?.feedback}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FeedbackModal;
