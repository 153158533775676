/* eslint-disable import/extensions */

import React from 'react';

import { useAuth } from 'services/auth/authContext';

// interface ProtectedRouteProps {
//   children: React.ReactNode;
// }

// const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
const ProtectedRoute = (props: { children: React.ReactNode }) => {
  const { currentUser } = useAuth();

  return <>{currentUser ? props.children : null}</>;

  // if (currentUser) {
  //   return <Route {...rest} element={element} />;
  // }
  // return <Navigate to="/login" replace />;
};
export default ProtectedRoute;
