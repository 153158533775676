import React, { useCallback, useEffect, useState } from 'react';

import type { DocumentData } from 'firebase/firestore';
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  Timestamp,
} from 'firebase/firestore';

import { Alert, AlertTitle, Box, Container, List, Modal } from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { endOfDay, startOfDay } from 'date-fns';

import Routes from 'AppRoutes/Routes';

import Header from 'components/Header';

import { useAuth } from 'services/auth/authContext';

import VoucherCard from 'components/VoucherCard';

import { firestore } from 'services/firebase/client';
import type { IFeedbacks, IVoucher } from 'utils/types/Vouchers';
import FeedbackModal from 'components/FeedbackModal';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Vouchers = (): React.JSX.Element => {
  const { auth } = useAuth();

  const [vouchers, setVouchers] = useState<IVoucher[]>([]);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [dateError, setDateError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [currentFeedback, setCurrentFeedback] = useState<IFeedbacks>();

  const handleModal = useCallback((feedback: IFeedbacks, openFlag: boolean) => {
    setCurrentFeedback(feedback);
    setOpen(openFlag);
  }, []);

  const handleStartDateChange = (date: Date | null) => {
    if (!date) {
      return;
    }
    setDateError(false);
    if (selectedEndDate && selectedEndDate < date) {
      setDateError(true);
    }

    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    if (!date) {
      return;
    }

    setDateError(false);

    if (selectedStartDate && selectedStartDate > date) {
      setDateError(true);
    }
    setSelectedEndDate(date);
  };

  useEffect(() => {
    const voucherCollection = collection(firestore, 'vouchers');

    let startTimestamp = Timestamp.fromDate(new Date());
    let endTimestamp = Timestamp.fromDate(new Date());

    let q = query(
      voucherCollection,
      where('houseID', '==', auth.currentUser?.uid),
    );

    if (selectedStartDate && selectedEndDate) {
      const start = startOfDay(new Date(selectedStartDate));
      const end = endOfDay(new Date(selectedEndDate));

      startTimestamp = Timestamp.fromDate(start);
      endTimestamp = Timestamp.fromDate(end);

      q = query(
        q,
        where('createdAt', '>=', startTimestamp),
        where('createdAt', '<=', endTimestamp),
      );
    }

    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(q);
        const updatedVouchers: IVoucher[] = [];

        querySnapshot.forEach((doc: DocumentData) => {
          updatedVouchers.push({
            id: doc.id,
            ...doc.data(),
          } as IVoucher);
        });

        updatedVouchers.sort(
          (a, b) =>
            b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime(),
        );
        setVouchers(updatedVouchers);
      } catch (error) {
        console.error('Error fetching vouchers:', error);
      }
    };

    const unsubscribe = onSnapshot(q, querySnapshot => {
      const updatedVouchers: IVoucher[] = [];

      querySnapshot.forEach((doc: DocumentData) => {
        updatedVouchers.push({
          id: doc.id,
          ...doc.data(),
        } as IVoucher);
      });

      updatedVouchers.sort(
        (a, b) =>
          b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime(),
      );

      setVouchers(updatedVouchers);
    });

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();

    // Unsubscribe from the real-time listener when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [auth.currentUser?.uid, selectedEndDate, selectedStartDate]);

  return (
    <section>
      <Header route={Routes.VOUCHERS} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {currentFeedback?.feedback}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {currentFeedback?.feedback}
          </Typography>
        </Box> */}
        <Box sx={style}>
          <FeedbackModal feedback={currentFeedback} />
        </Box>
      </Modal>
      <Container
        style={{
          paddingLeft: 40,
          paddingTop: 40,
          flex: 2,
        }}
      >
        {dateError && (
          <Box marginBottom={2}>
            <Alert severity="error">
              <AlertTitle>Erro</AlertTitle>
              <strong>Data final</strong> deve ser maior que a
              <strong> Data Inicial</strong>
            </Alert>
          </Box>
        )}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            display="flex"
            marginBottom={5}
            justifyContent="space-between"
            width={500}
          >
            <DatePicker
              label="Data inicial"
              format="DD/MM/YYYY" // Set the desired date format
              onChange={handleStartDateChange}
            />
            <DatePicker
              label="Data final"
              format="DD/MM/YYYY" // Set the desired date format
              onChange={handleEndDateChange}
            />
          </Box>
        </LocalizationProvider>
        <Container
          style={{
            display: 'flex',
            // justifyContent: 'center',
          }}
        >
          <List>
            {vouchers.map(item => {
              return (
                <VoucherCard
                  key={item.voucherID}
                  keyIndex={item.voucherID ?? new Date().toLocaleDateString()}
                  name={item.userName || 'Usuário sem nome'}
                  date={item.createdAt.toDate().toLocaleString()}
                  feedback={item.feedback}
                  handleModal={handleModal}
                />
              );
            })}
          </List>
        </Container>
      </Container>
    </section>
  );
};

export default Vouchers;
