/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';

import {
  Alert,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';

import * as htmlToImage from 'html-to-image';

import QRCode from 'react-qr-code';

import { sendPasswordResetEmail } from 'firebase/auth';

import { useAuth } from 'services/auth/authContext';

import { auth as firebaseAuth } from 'services/firebase/client';
import Header from 'components/Header';
import Routes from 'AppRoutes/Routes';
import { getLegalTerms } from 'services/firebase/updates';

const Account = (): React.JSX.Element => {
  const [email, setEmail] = useState('');
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [term, setTerm] = useState('');
  const [pngImage, setPngImage] = useState<string | null>(null);

  const qrCodeRef = useRef<HTMLDivElement | null>(null);
  const { auth } = useAuth();

  // const handleDownload = () => {
  //   if (!qrCodeRef.current) {
  //     return;
  //   }
  //   const svgElement =
  //     qrCodeRef.current && qrCodeRef.current.querySelector('svg');

  //   if (svgElement) {
  //     const svgString = new XMLSerializer().serializeToString(svgElement);
  //     const blob = new Blob([svgString], { type: 'image/svg+xml' });
  //     const url = URL.createObjectURL(blob);

  //     // eslint-disable-next-line no-undef
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.download = 'qrcode.svg';
  //     link.click();

  //     URL.revokeObjectURL(url);
  //   }
  // };

  useEffect(() => {
    const svgElement =
      qrCodeRef.current && qrCodeRef.current.querySelector('svg');

    if (svgElement) {
      htmlToImage
        .toPng(svgElement as unknown as HTMLElement)
        .then(dataUrl => {
          setPngImage(dataUrl);
        })
        .catch(error => {
          console.error('Error converting SVG to PNG:', error);
        });
    }
  }, []);

  const handleDownload = () => {
    if (pngImage) {
      // eslint-disable-next-line no-undef
      const link = document.createElement('a');
      link.href = pngImage;
      link.download = 'qrcode.png';
      link.click();
    }
  };

  const handleEmailChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setEmail(e.target.value);
    setIsInvalidEmail(false);
    setEmailSent(false);
  };

  const handleSendClick = async () => {
    // Call your Firebase endpoint for password recovery here
    if (email === '') {
      return;
    }
    try {
      await sendPasswordResetEmail(firebaseAuth, email);
      setEmailSent(true);
      setEmail('');
    } catch (error) {
      setIsInvalidEmail(true);
    }
  };

  const handleOpenModal = async () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const terms = await getLegalTerms();
      if (terms) {
        setTerm(terms);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, []);

  return (
    <>
      <Header route={Routes.ACCOUNT} />
      <Container component="main" maxWidth="xs" style={{ marginTop: 50 }}>
        <div>
          <Typography component="h1" variant="h5" style={{ marginBottom: 10 }}>
            Trocar senha
          </Typography>
          <Typography variant="body1" style={{ marginBottom: 40 }}>
            Informe o e-mail de seu cadastro. Enviaremos um link para que você
            possa alterar sua senha.
          </Typography>
          <form noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-mail"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={handleEmailChange}
              style={{ marginBottom: 20 }}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              // className={classes.submit}
              onClick={handleSendClick}
            >
              Enviar
            </Button>
          </form>
        </div>
        {isInvalidEmail && (
          <Alert variant="filled" severity="error" style={{ marginTop: 50 }}>
            <strong>E-mail inválido!</strong>
          </Alert>
        )}
        {emailSent && (
          <Alert variant="filled" severity="success" style={{ marginTop: 50 }}>
            <strong>E-mail enviado com sucesso!</strong>
          </Alert>
        )}
      </Container>

      <Container
        // component="main"
        // maxWidth="xs"
        style={{
          marginTop: 50,
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3">***</Typography>
        <Button
          size="small"
          onClick={handleOpenModal}
          style={{
            color: 'black',
            textDecoration: 'underline',
            flex: 1,
            fontSize: 18,
          }}
        >
          Termos de uso e política de privacidade
        </Button>
        <Dialog open={openModal} onClose={handleCloseModal}>
          <DialogTitle> Termos de uso e política de privacidade</DialogTitle>
          <DialogContent>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{ __html: term }}
            />
          </DialogContent>
        </Dialog>
        <Typography variant="h3">***</Typography>

        <Typography component="h1" variant="h5" style={{ marginBottom: 20 }}>
          QR CODE
        </Typography>
        <Container
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div ref={qrCodeRef}>
            <QRCode value={auth.currentUser?.uid ?? ''} />
          </div>
          <Button
            size="small"
            onClick={handleDownload}
            style={{
              color: 'black',
              textDecoration: 'underline',
              flex: 1,
              fontSize: 18,
            }}
          >
            Download QR Code
          </Button>
        </Container>
      </Container>
    </>
  );
};

export default Account;
