/* eslint-disable prettier/prettier */
import React, { useCallback, useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Switch,
  Paper,
  FormControlLabel,
  Select,
  MenuItem,
  IconButton,
  Fab,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import type { IAvailability } from 'utils/types/HousePromotion';


const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];


const availableMealTypes = ['breakfast', 'lunch', 'dinner', 'brunch'];


const mapDayOfWeek = (day: string) => {
  switch (day) {
    case 'mon':
      return 'seg';
    case 'tue':
      return 'ter';
    case 'wed':
      return 'qua';
    case 'thu':
      return 'qui';
    case 'fri':
      return 'sex';
    case 'sat':
      return 'sáb';
    case 'sun':
      return 'dom';
    default:
      return day; // If the input is not recognized, return it as-is
  }
};

const mapMealType = (meal: string) => {
  switch (meal.toLowerCase()) {
    case 'lunch':
      return 'almoço';
    case 'breakfast':
      return 'café da manhã';
    case 'brunch':
      return 'brunch';
    case 'dinner':
      return 'jantar';
    default:
      return meal; // If the input is not recognized, return it as-is
  }
};
interface IAvailabilityEditorProps {
  availability: IAvailability;
  onUpdate: (availabilityData: IAvailability) => void;
}

const AvailabilityEditor = ({
  availability,
  onUpdate,
}: IAvailabilityEditorProps): React.JSX.Element => {
  const [availabilityData, setAvailabilityData] = useState(availability);
  const [selectedMealType, setSelectedMealType] = useState('');


  const handleSwitchChange = useCallback(
    (mealType: string, day: string) => {
      setAvailabilityData(prevData => ({
        ...prevData,
        [mealType]: {
          ...prevData[mealType],
          [day]: !prevData[mealType][day],
        },
      }));

      const update = {
        ...availabilityData, [mealType]: {
          ...availabilityData[mealType],
          [day]: !availabilityData[mealType][day],
        },
      };
      onUpdate(update);

      // Call the onUpdate function to update Firebase
      // onUpdate(availabilityData);
    },
    [availabilityData, onUpdate],
  );

  const addMealType = () => {
    if (selectedMealType && availableMealTypes.includes(selectedMealType)) {
      // Create a new mealType with all columns initialized as false
      const newMealType = {
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false,
      };

      // Update the local state by adding the new mealType
      setAvailabilityData((prevData) => ({
        ...prevData,
        [selectedMealType]: newMealType,
      }));

      const update = { ...availabilityData, [selectedMealType]: newMealType };
      onUpdate(update);

      // Clear the selected meal type
      setSelectedMealType('');

    }
  };

  const removeMealType = useCallback((mealType: string) => {
    // Create a copy of the availability data without the specified meal type
    const updatedAvailabilityData = { ...availabilityData };
    delete updatedAvailabilityData[mealType];

    // Update the local state
    setAvailabilityData(updatedAvailabilityData);
    onUpdate(updatedAvailabilityData);


    // Call the onUpdate function to update Firebase
    // onUpdate(updatedAvailabilityData);

  }, [availabilityData, onUpdate]);

  const remainingMealTypes = availableMealTypes.filter((mealType) => !availabilityData[mealType.toLowerCase()]);


  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            {daysOfWeek.map((day: string) => (
              <TableCell key={day}>{mapDayOfWeek(day)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(availabilityData).map(mealType => (
            <TableRow key={mealType} style={{ alignItems: 'center' }}>
              <TableCell>{mapMealType(mealType)}</TableCell>
              {daysOfWeek.map(day => (
                <TableCell key={day}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={availabilityData[mealType][day]}
                        onChange={() => handleSwitchChange(mealType, day)}
                      />
                    }
                    label=""
                  />
                </TableCell>
              ))}
              <TableCell>
                <IconButton
                  onClick={() => removeMealType(mealType)}
                  color="error"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Select
        value={selectedMealType}
        onChange={(event) => setSelectedMealType(event.target.value)}
      >
        <MenuItem value="Adicione outro período">Adicione outro período</MenuItem>
        {remainingMealTypes.map((mealType) => (
          <MenuItem key={mealType} value={mealType}>
            {mapMealType(mealType)}
          </MenuItem>
        ))}
      </Select>

      <Fab size="medium" color="primary" aria-label="add" onClick={addMealType}>
        <AddIcon />
      </Fab>


    </Paper>
  );
};

export default AvailabilityEditor;
