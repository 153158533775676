import type { ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { doc, collection, getDoc } from 'firebase/firestore';

import type { IHouse } from 'utils/types/Houses';
import { firestore } from 'services/firebase/client';

type TUpdateHouseDataFunction = (id: string) => Promise<void>;

interface IHouseDataContextType {
  houseData: IHouse | null;
  updateHouseData: TUpdateHouseDataFunction;
}

const HouseDataContext = createContext<IHouseDataContextType | null>(null);

export const useHouseData = (): IHouseDataContextType | null => {
  return useContext(HouseDataContext);
};

// User Data Provider component
interface IHouseDataProviderProps {
  children: ReactNode;
}

export const HouseDataProvider = ({ children }: IHouseDataProviderProps) => {
  const [houseData, setHouseData] = useState<IHouse | null>(null);

  const updateHouseData = useCallback(async (id: string) => {
    try {
      const houseCollection = collection(firestore, 'houses');
      const docRef = doc(houseCollection, id);

      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const data = docSnapshot.data();

        const populatedHouseData: IHouse = {
          id,
          corporateName: data.corporateName,
          UF: data.UF,
          zipcode: data.zipcode,
          address: data.address,
          city: data.city,
          cnpj: data.cnpj,
          image: data.image,
          instagram: data.instagram,
          whatsapp: data.whatsapp,
          neighborhood: data.neighborhood,
          open: data.open,
          name: data.name,
          phone: data.phone,
          price: data.price,
          discountPercentage: data.discountPercentage,
          promotionID: data.promotionID,
          promotionImage: data.promotionImage,
          rating: {
            average: data.rating.average,
            evaluations: data.rating.evaluations,
          },
          status: data.status,
          style: data.style,
          thumbnailURL: data.thumbnailURL,
          type: data.type,
          benefits: {
            kids: data.benefits.kids,
            pets: data.benefits.pets,
            internalArea: data.benefits.internalArea,
            parking: data.benefits.parking,
            outsideArea: data.benefits.outsideArea,
            craftBeer: data.benefits.craftBeer,
          },
        };

        setHouseData(populatedHouseData);

        // Set the fetched user data if needed
        // Example: setUser(data);
      } else {
        // Clear the states
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  }, []);

  const contextValue = useMemo(() => {
    return {
      houseData,
      updateHouseData,
    };
  }, [houseData, updateHouseData]);

  return (
    <HouseDataContext.Provider value={contextValue}>
      {children}
    </HouseDataContext.Provider>
  );
};
