import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import Home from 'AppRoutes/Home'; // Import your route components

import Login from 'AppRoutes/Login';

import Vouchers from 'AppRoutes/Vouchers';

import Promotions from 'AppRoutes/Promotions';

import ProtectedRoute from './ProtectedRoute'; // Import the ProtectedRoute HOC
import RecoveryPassword from './RecoveryPassword';
import Account from './Account';
import SignUp from './SignUp';

const AppRoutes: React.FC = () => {
  // console.log('Entrou em AppRoutes');
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/recovery" element={<RecoveryPassword />} />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vouchers"
          element={
            <ProtectedRoute>
              <Vouchers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/promotions"
          element={
            <ProtectedRoute>
              <Promotions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account"
          element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
