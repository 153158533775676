import axios from 'axios';

export interface IState {
  id: number;
  nome: string;
  regiao: {
    id: number;
    nome: string;
    sigla: string;
  };
  sigla: string;
}

export interface ICity {
  id: number;
  microrregiao: {
    id: number;
    mesorregiao: {
      UF: {
        id: number;
        nome: string;
        regiao: {
          id: number;
          nome: string;
          sigla: string;
        };
        sigla: string;
      };
      id: number;
      nome: string;
    };
    nome: string;
  };
  nome: string;
}

// eslint-disable-next-line consistent-return
export const fetchStates = async (): Promise<IState[] | undefined> => {
  try {
    const response = await axios.get(
      'https://servicodados.ibge.gov.br/api/v1/localidades/estados',
    );

    const fetchedStates = response.data;
    return fetchedStates;
  } catch (error) {
    console.error('Error fetching states:', error);
  }
};

export const fetchCitiesByStateId = async (
  state: string,
  // eslint-disable-next-line consistent-return
): Promise<ICity[] | undefined> => {
  try {
    const response = await axios.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`,
    );

    const cities = response.data;
    return cities;
  } catch (error) {
    console.error('Error fetching cities:', error);
  }
};
