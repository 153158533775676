import React, { useCallback, useEffect, useState } from 'react';

// Importing Material UI components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Grid, Typography } from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useNavigate } from 'react-router-dom';

import LogoutIcon from '@mui/icons-material/Logout';

import Logo from 'assets/icons/club_icon.png';

import { useAuth } from 'services/auth/authContext';

import MenuDrawer from 'components/MenuDrawer';

import { routesInfo } from 'AppRoutes/Routes';

import styles from './styles';

interface IHeaderProps {
  route: string;
  hasBackButton?: boolean;
}

const Header = ({ route, hasBackButton }: IHeaderProps): React.JSX.Element => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');

  const onClose = useCallback(() => setIsOpen(false), []);
  const onOpen = useCallback(() => setIsOpen(true), []);

  const handleLogout = async () => {
    try {
      await auth.signOut(); // Sign the user out
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const onBack = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    routesInfo.map((item): void => {
      if (item.path === route) {
        setTitle(item.title);
      }
    });
  }, [route]);
  return (
    <AppBar position="static" sx={styles.navBar}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={3}>
          <Toolbar>
            {auth.currentUser ? (
              <>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={onOpen}
                >
                  <MenuIcon />
                </IconButton>
                <MenuDrawer isOpen={isOpen} onClose={onClose} />
              </>
            ) : null}

            {hasBackButton && !auth.currentUser ? (
              <>
                <IconButton edge="start" color="inherit" onClick={onBack}>
                  <ArrowBackIcon />
                </IconButton>
                <Button onClick={onBack} color="inherit">
                  Voltar
                </Button>
              </>
            ) : (
              <Typography variant="h6" component="div" sx={styles.text}>
                {title}
              </Typography>
            )}
          </Toolbar>
        </Grid>
        <Grid item xs={6} sx={styles.logoContainer}>
          <img src={Logo} alt="Logo" style={{ height: 40, marginRight: 16 }} />
        </Grid>
        <Grid item xs={3} sx={styles.profileGrid}>
          {auth.currentUser ? (
            <>
              <LogoutIcon onClick={handleLogout} />
              <Button onClick={handleLogout} color="inherit">
                Sair
              </Button>
            </>
          ) : null}
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default Header;
