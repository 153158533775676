import { createTheme } from '@mui/material/styles';

import customColors from './colors'; // Import your custom colors

const theme = createTheme({
  palette: {
    primary: {
      main: customColors.yellowFestGourmet, // Use your custom lightBlue color
    },
    secondary: {
      main: customColors.darkBlue, // Use your custom darkBlue color
    },
    // You can similarly define other custom colors here
  },
  // Other theme configuration options
});

export default theme;
